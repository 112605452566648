var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-bulk-devices" }, [
    _c("div", { staticClass: "toolbar justify-content-between" }, [
      _c("div", [
        _c("i", {
          directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
          staticClass: "icon-button",
          class: _vm.$config.icons.general.back,
          attrs: { title: "Back to Admin" },
          on: { click: _vm.clickBack }
        })
      ])
    ]),
    _c(
      "div",
      { staticClass: "list-container" },
      [
        _c(
          "b-card",
          [
            _c("b-card-header", [_c("h2", [_vm._v("Bulk Add Devices")])]),
            _c(
              "b-card-body",
              [
                _c("action-stepper", {
                  ref: "actionStepper",
                  attrs: { steps: _vm.actionSteps },
                  scopedSlots: _vm._u([
                    {
                      key: "first",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "form-section" },
                            [
                              _c("div", { staticClass: "option-row mb-2" }, [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "b-checkbox",
                                      {
                                        on: {
                                          change: _vm.changeOptionAssignSIM
                                        },
                                        model: {
                                          value: _vm.optionAssignSIM,
                                          callback: function($$v) {
                                            _vm.optionAssignSIM = $$v
                                          },
                                          expression: "optionAssignSIM"
                                        }
                                      },
                                      [_vm._v("Assign SIM")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.optionAssignSIM,
                                        expression: "optionAssignSIM"
                                      }
                                    ]
                                  },
                                  [
                                    _c(
                                      "b-checkbox",
                                      {
                                        model: {
                                          value: _vm.optionUseICCD,
                                          callback: function($$v) {
                                            _vm.optionUseICCD = $$v
                                          },
                                          expression: "optionUseICCD"
                                        }
                                      },
                                      [_vm._v("Use ICCD")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-grow-1",
                                    class: {
                                      "form-error":
                                        _vm.$v.optionDeviceType.$error
                                    }
                                  },
                                  [
                                    _vm._v(" Device Type: "),
                                    _c("b-select", {
                                      attrs: { options: _vm.deviceTypes },
                                      model: {
                                        value: _vm.$v.optionDeviceType.$model,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.$v.optionDeviceType,
                                            "$model",
                                            $$v
                                          )
                                        },
                                        expression: "$v.optionDeviceType.$model"
                                      }
                                    }),
                                    !_vm.$v.optionDeviceType.required
                                      ? _c(
                                          "div",
                                          { staticClass: "input-error" },
                                          [_vm._v("Select a device type.")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]),
                              _c("admin-barcode-input", {
                                ref: "deviceInput",
                                attrs: {
                                  "use-iccd": _vm.optionUseICCD,
                                  "validate-sim": _vm.optionAssignSIM
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "second",
                      fn: function() {
                        return [
                          _c("h3", [_vm._v("Results")]),
                          _c(
                            "div",
                            { staticClass: "form-section" },
                            [
                              _vm.deviceList
                                ? _c("admin-device-list", {
                                    attrs: {
                                      devices: _vm.deviceList,
                                      "show-fields": _vm.deviceFields,
                                      "hide-filter": ""
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                }),
                _c("div", { staticClass: "green-divider" }),
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { disabled: _vm.currentStep === 0 },
                        on: {
                          click: function($event) {
                            return _vm.prevAction()
                          }
                        }
                      },
                      [_vm._v("Back")]
                    ),
                    _c(
                      "b-button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.nextButtons[_vm.currentStep].function()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.nextButtons[_vm.currentStep].title))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }