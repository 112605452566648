<template>
  <div class="admin-bulk-devices">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
    </div>
    <div class="list-container">
      <b-card>
        <b-card-header><h2>Bulk Add Devices</h2></b-card-header>
        <b-card-body>
          <action-stepper :steps="actionSteps" ref="actionStepper">
            <template v-slot:first>
              <div class="form-section">
                <div class="option-row mb-2">
                  <div>
                    <b-checkbox v-model="optionAssignSIM" @change="changeOptionAssignSIM">Assign SIM</b-checkbox>
                  </div>
                  <div v-show="optionAssignSIM">
                    <b-checkbox v-model="optionUseICCD">Use ICCD</b-checkbox>
                  </div>
                  <div :class="{ 'form-error': $v.optionDeviceType.$error }" class="flex-grow-1">
                    Device Type:
                    <b-select :options="deviceTypes" v-model="$v.optionDeviceType.$model"></b-select>
                    <div class="input-error" v-if="!$v.optionDeviceType.required">Select a device type.</div>
                  </div>
                </div>
                <admin-barcode-input ref="deviceInput" :use-iccd="optionUseICCD" :validate-sim="optionAssignSIM"></admin-barcode-input>
              </div>
            </template>
            <template v-slot:second>
              <h3>Results</h3>
              <div class="form-section">
<!--              <admin-device-configurator :devices="deviceList" :hide-execute="true" ref="configurator"></admin-device-configurator>-->
                <admin-device-list v-if="deviceList" :devices="deviceList" :show-fields="deviceFields" hide-filter></admin-device-list>
              </div>
            </template>
          </action-stepper>
          <div class="green-divider"></div>
          <div class="">
            <b-button @click="prevAction()" :disabled="currentStep === 0">Back</b-button>
            <b-button @click="nextButtons[currentStep].function()">{{nextButtons[currentStep].title}}</b-button>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import * as ErrorHelper from '../../components/helpers/ErrorHelper'
import * as DataProvider from '../../components/helpers/DataProvider'
import * as AlertHelper from '../../components/helpers/AlertHelper'
import AdminBarcodeInput from '@/components/admin/AdminBarcodeInput'
import ActionStepper from '@/components/ActionStepper'
import AdminDeviceConfigurator from '@/components/admin/AdminDeviceConfigurator'
import { required } from 'vuelidate/lib/validators'
import AdminDeviceList from '@/components/admin/AdminDeviceList'

export default {
  name: 'admin-bulk-devices',
  components: {
    AdminDeviceList,
    AdminDeviceConfigurator,
    ActionStepper,
    AdminBarcodeInput
  },
  validations () {
    return {
      optionDeviceType: {
        required: required
      }
    }
  },
  data: function () {
    return {
      actionSteps: [
        {
          name: 'first',
          title: 'Add Devices',
          complete: false,
          active: true
        },
        {
          name: 'second',
          title: 'Results',
          complete: false,
          active: false
        }
      ],
      nextButtons: [
        {
          title: 'Create Devices',
          function: this.createDevices
        },
        {
          title: 'Setup Devices',
          function: this.setupDevices
        }
      ],
      currentStep: 0,
      deviceList: [],
      selectAll: false,
      setupCommandOptions: [],
      setupCommandSelect: null,
      currentCommand: null,
      currentCommandText: '',
      optionUseICCD: true,
      optionDeviceType: null,
      optionAssignSIM: true,
      deviceTypes: [{ value: null, text: 'Select a Device Type' }],
      deviceFields: ['selected', 'device_type', 'imei', 'device_code', 'name', 'billable', 'actions']
    }
  },
  created () {
    this.deviceTypes = this.deviceTypes.concat(this.$deviceService.getDeviceTypes().map(x => { return { value: x, text: x } }))
    console.log(this.deviceTypes)
    if (localStorage.getItem('deviceSetupCommandSets')) {
      this.setupCommandOptions = JSON.parse(localStorage.getItem('deviceSetupCommandSets'))
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    prevAction: function () {
      this.currentStep = this.$refs.actionStepper.prevAction()
    },
    createDevices: async function () {
      this.$v.optionDeviceType.$touch()
      if (this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast('Some Fields Contain Invalid Data. Please fix them.', 'Invalid Fields')
        return
      }
      if (!this.$refs.deviceInput.validate()) {
        ErrorHelper.displayGeneralErrorToast('Check your input fields, one or more show errors.', 'Errors')
        return
      }
      // Grab the list of IMEIs, deal with retries by checking for duplicates
      let createdDeviceIMEIs = []
      for (let device of this.$refs.deviceInput.devices) {
        if (device.device_imei === '') { // Ignore blanks
          continue
        }
        console.log('Creating ', device.device_imei)
        let deviceData = {
          device_imei: device.device_imei,
          device_phone: device.phone,
          device_type: this.optionDeviceType
        }
        if (!this.optionAssignSIM) {
          deviceData.device_phone = '0000000000'
        }
        let result = await DataProvider.adminCreateDevice(deviceData)
        if (result.success) {
          console.log(result.data)
          AlertHelper.successToast(
            `Created new device ${result.data.device_code}`,
            'Device Creation Successful'
          )
          if (this.optionAssignSIM) {
            let simAssignResult = await DataProvider.adminUpdateSIM(device.sim_id, null, device.device_imei)
            if (!simAssignResult.success) {
              ErrorHelper.displayDataErrorToast(simAssignResult)
            }
            let updateDevice = await DataProvider.getDeviceByIMEI(device.device_imei, [
              'sim',
              'billable',
              'device_code',
              'device_id',
              'device_imei',
              'device_name',
              'device_password',
              'device_phone',
              'device_type',
              'has_subscription',
              'icon',
              'imei',
              'name',
              'plant_num',
              'registration',
              'settings',
              'user_id'
            ])
            result = updateDevice
          }
          this.deviceList.push(result.data)
          createdDeviceIMEIs.push(device.device_imei)
        } else {
          ErrorHelper.displayDataErrorToast(result)
        }
      }
      this.$refs.deviceInput.devices = this.$refs.deviceInput.devices.filter(device => !createdDeviceIMEIs.includes(device.device_imei))
      if (this.deviceList.length > 0) {
        console.log(this.deviceList)
        this.actionSteps[0].complete = true
        this.currentStep = this.$refs.actionStepper.nextAction()
      }
    },
    setupDevices: function () {
      // console.log('setup')
      // this.$refs.configurator.clickExecute()
    },
    toggleSelectAll: function (event) {
      if (event) {
        this.deviceList.forEach(x => { x.selected = true })
      } else {
        this.deviceList.forEach(x => { x.selected = false })
      }
    },
    selectChange: function (user) {
      this.selectAll = false
    },
    // Create a new unique ID for an optionlist
    createUniqueId: function (optionList) {
      let exists = true
      let id = optionList.length
      while (exists) { // Find a unique ID. Otherwise the select element will behave oddly
        exists = optionList.find(x => x.value === id) !== undefined
        if (exists) {
          id++
        }
      }
      return id
    },
    setupCommandAdd: function () {
      let id = this.createUniqueId(this.setupCommandOptions)
      this.setupCommandOptions.push({
        value: id,
        text: 'New Commands ' + id,
        commands: []
      })
    },
    setupCommandRemove: function () {
      console.log(this.setupCommandSelect)
      if (this.setupCommandSelect !== null) {
        this.setupCommandOptions.splice(this.setupCommandSelect, 1)
      }
    },
    keydownHandler: function (event) {
      if (event.which === 13) {
        this.sendCommand()
      }
    },
    onCommandTextChange: function (command) {
      console.log(command)
      this.currentCommandText = command.command
    },
    addCommandText: function () {
      if (this.currentCommandText.length > 0) {
        let id = this.createUniqueId(this.setupCommandOptions[this.setupCommandSelect].commands)
        this.setupCommandOptions[this.setupCommandSelect].commands.push({
          text: this.currentCommandText,
          value: id
        })
      }
    },
    removeCommandText: function (command) {
      let idx = this.setupCommandOptions[this.setupCommandSelect].commands.indexOf(command)
      this.setupCommandOptions[this.setupCommandSelect].commands.splice(idx, 1)
    },
    saveSetupCommands: function () {
      console.log('saved command')
      localStorage.setItem('deviceSetupCommandSets', JSON.stringify(this.setupCommandOptions))
    },
    setAllSetupCommands: function (id) {
      console.log(id)
      this.deviceList.forEach(device => { device.setupOptionIdx = id })
    },
    changeOptionAssignSIM: function (newVal) {
      this.optionUseICCD = newVal
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-bulk-devices {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-container {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .option-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .device-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .status-failed {
    color: red;
  }

  .form-section {
    overflow: auto;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }
</style>
